/* eslint-disable vue/no-deprecated-destroyed-lifecycle */
import Vue from 'vue';

export default Vue.extend({
  updated() {
    // menu-down to chevron-down
    const selectorsMenuDownIcon = document.querySelectorAll(
      '.v-select__slot .v-icon.mdi-menu-down'
    );

    if (selectorsMenuDownIcon.length > 0) {
      selectorsMenuDownIcon.forEach((item) => {
        item.classList.replace('mdi-menu-down', 'mdi-chevron-down');
      });
    }
  },
});
