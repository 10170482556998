<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="a"
      d="M5.07812 5V3.13346C5.07812 2.38673 5.07812 2.01308 5.22345 1.72786C5.35128 1.47698 5.55511 1.27316 5.80599 1.14532C6.0912 1 6.46485 1 7.21159 1H10.9449C11.6917 1 12.0648 1 12.35 1.14532C12.6009 1.27316 12.8051 1.47698 12.9329 1.72786C13.0783 2.01308 13.0783 2.38645 13.0783 3.13319V6.86654C13.0783 7.61327 13.0783 7.98664 12.9329 8.27186C12.8051 8.52274 12.6007 8.72699 12.3499 8.85482C12.0649 9 11.6921 9 10.9468 9H9.07812M5.07812 5H3.21159C2.46485 5 2.09121 5 1.80599 5.14532C1.55511 5.27316 1.35128 5.47698 1.22345 5.72786C1.07812 6.01308 1.07812 6.38673 1.07812 7.13346V10.8668C1.07812 11.6135 1.07812 11.9867 1.22345 12.2719C1.35128 12.5228 1.55511 12.727 1.80599 12.8548C2.09093 13 2.46412 13 3.2094 13H6.94716C7.69244 13 8.06511 13 8.35005 12.8548C8.60093 12.727 8.80511 12.5226 8.93294 12.2717C9.07812 11.9868 9.07812 11.614 9.07812 10.8687V9M5.07812 5H6.94492C7.69166 5 8.06483 5 8.35005 5.14532C8.60093 5.27316 8.80511 5.47698 8.93294 5.72786C9.07813 6.0128 9.07813 6.386 9.07813 7.13129L9.07812 9"
      stroke="#222122"
      stroke-width="1.34737"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</template>

<script>
  export default {
    name: 'Copy',
  };
</script>

<style lang="scss" scoped>
  .a {
    stroke: currentColor;
  }
</style>
