<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.33594 13.7148V17H16.3359V13.7148M8.83572 1V12.9999M8.83572 12.9999L13.6968 7.99995M8.83572 12.9999L3.97461 7.99995"
      stroke="#191919"
      stroke-width="1.6"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="a"
    ></path>
  </svg>
</template>

<script>
  export default {
    name: 'Download',
  };
</script>

<style lang="scss" scoped>
  .a {
    stroke: currentColor;
  }
</style>
