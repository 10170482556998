<template>
  <v-app
    id="app"
    :class="$route?.name && 'page--' + $route.name.toString().toLowerCase()"
  >
    <router-view></router-view>
    <Notification />
    <LoadNotification />

    <div id="root-tooltip-wrapper"></div>
    <!-- <CountDownNotification /> -->
  </v-app>
</template>

<script>
  import Notification from '@/components/notification/Notification.vue';
  import LoadNotification from '@/components/load-notification/LoadNotification.vue';
  import StorageHelper from '@/helpers/localStorageHelper';
  import TimeZoneAppSetting from '@/models/constants/TimeZone';
  import selectorMenuDownToChevronDownMixin from '@/plugins/selectorMenuDownToChevronDownMixin';
  // import CountDownNotification from '@/components/count-down-notification/CountDownNotification.vue';

  export default {
    name: 'App',
    components: {
      Notification,
      LoadNotification,
      // CountDownNotification,
    },
    created() {
      this.$nextTick(() => {
        const nightMode = StorageHelper.get('nightMode');
        if (nightMode !== undefined && nightMode !== null) {
          this.nightMode = nightMode;
        } else {
          this.nightMode = 'LIGHT';
        }

        const tz = StorageHelper.get('timeZone');

        if (tz && tz === TimeZoneAppSetting.Local) {
          StorageHelper.set('timeZoneValue', this.detectTZ());
        } else {
          StorageHelper.remove('timeZoneValue');
        }
      });
    },
    mixins: [selectorMenuDownToChevronDownMixin],
    methods: {
      detectTZ() {
        const utcOffsetMinutes = new Date().getTimezoneOffset();
        const offsetHours = Math.floor(Math.abs(utcOffsetMinutes) / 60);
        const offsetMinutes = Math.abs(utcOffsetMinutes) % 60;
        const sign = utcOffsetMinutes <= 0 ? '+' : '-';
        const formattedOffset = `${sign}${String(offsetHours).padStart(
          2,
          '0'
        )}:${String(offsetMinutes).padStart(2, '0')}`;

        return formattedOffset;
      },
    },
    watch: {
      nightMode(val) {
        this.switchTheme(val);
      },
    },
  };
</script>
